import React, { useContext } from "react";
import { Row, Col, Button, Typography, Tag, Progress } from "antd";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import moment from "moment";
import NoData from "../../../components/NoData";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { confirmStartAssignment } from "../../../utils/exercises";
import bee_sad from "../../../assets/images/medium.png";
import CustomExerciseLgIcon from "../../../components/Icons/CustomExerciseLgIcon";
import CustomTestLgIcon from "../../../components/Icons/CustomTestLgIcon";
import avatar from "../../../assets/images/avatar.png";
import good from "../../../assets/images/good.png";
import { StoreContext } from "../../../stores";
import StudentChartSkill from "../../../components/StudentChartSkill";
import { AASSIGNMENT } from "../../../apis/exercise";
import { AREPORT } from "../../../apis/report";

const { Title, Text } = Typography;

function StudentDashboard() {
    const { me } = useContext(StoreContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    let currentBook = JSON.parse(localStorage.getItem("currentBook"));
    let bookmarks = JSON.parse(localStorage.getItem("bookmarks")) || {};

    const { data: assignmentData, isLoading: assignmentLoading } = useQuery(
        ["unsubmittedAssignments"],
        async () => {
            const response = await handleFetchApi(AASSIGNMENT.getBySubmitted({ status: false }));
            const groupedData = response?.data.reduce((acc, assignment) => {
                const status = assignment.status || "NOT_YET_STARTED";
                if (!acc[status]) {
                    acc[status] = [];
                }
                acc[status].push(assignment);
                return acc;
            }, {});
            return groupedData;
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: 180000, // 3 minutes
        }
    );

    const { data: reportData, isLoading: reportLoading } = useQuery(
        ["dailyReport"],
        async () => {
            const response = await handleFetchApi(
                AREPORT.search({ params: `?report_date=${moment().format("YYYY-MM-DD")}&class_id=${me?.schoolClasses[0]?.id}` })
            );

            return response?.data[0];
        },
        {
            refetchOnWindowFocus: false,
            cacheTime: 180000, // 3 minutes
        }
    );

    const renderAssignments = (assignments) => {
        return assignments.map((assignment) => {
            const { id, gradingId, deadlineAt, type, status } = assignment;

            return (
                <div
                    className={`card --padding --lg ${gradingId && "card_assignment __NORMAL"} flex__between__center gap`}
                    key={assignment.id}
                    style={{ marginBottom: 12 }}
                >
                    <div className="flex__start__center gap">
                        {type === "TEST" ? <CustomTestLgIcon /> : <CustomExerciseLgIcon />}
                        <div>
                            <Title level={4}>{assignment.name}</Title>
                            <Typography.Paragraph>
                                {t("deadline")}: {deadlineAt ? moment(deadlineAt).format("YYYY/MM/DD HH:mm") : "Không có"}
                            </Typography.Paragraph>
                            {gradingId && (
                                <Progress
                                    percent={(assignment?.numberExercisesDoing / assignment?.itemIds?.length) * 100}
                                    strokeColor={"#3B8AFF"}
                                    strokeWidth={24}
                                />
                            )}
                        </div>
                    </div>
                    <Button
                        className={!gradingId && "btn-test"}
                        type="primary"
                        size="large"
                        onClick={() => {
                            const { gradingId } = assignment;
                            if (gradingId) return navigate(`/assignment/${id}/grading/${gradingId}`);
                            else return confirmStartAssignment({ assignment: assignment, navigate: navigate });
                        }}
                    >
                        {status === "DOING" ? t("continue") : t("start")}
                    </Button>
                </div>
            );
        });
    };
    if (assignmentLoading || reportLoading) return <Loading />;

    return (
        <Row gutter={36}>
            <Col xs={24} md={24} lg={14} xl={16}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        {currentBook && (
                            <>
                                <Title level={4} className="flex__between__center">
                                    {t("book_reading")}
                                </Title>
                                <div className="card --padding --lg flex__between__center flex__wrap current_book">
                                    <div className="flex__start__center gap">
                                        <img src={currentBook?.cover_url} alt={currentBook.title} style={{ maxWidth: 120 }} />
                                        <div>
                                            <Tag color="#FFEB5B">{t("book_reading")}</Tag>
                                            <Title style={{ marginTop: 12 }} level={4}>
                                                {currentBook.title}
                                            </Title>
                                            <Text type="secondary">Bạn đang đọc dở tại trang {bookmarks[currentBook?.book?.id]}</Text>
                                        </div>
                                    </div>
                                    <Button type="primary" size="large" onClick={() => navigate(`/book/${currentBook.book_id}`)}>
                                        {t("continue_reading")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col span={24}>
                        <Title level={4} className="flex__between__center">
                            {t("inProgress")}{" "}
                            <Button type="link" onClick={() => navigate(`/exercises`)}>
                                XEM TẤT CẢ
                            </Button>
                        </Title>
                        {assignmentData?.DOING?.length ? (
                            renderAssignments(assignmentData.DOING.slice(0, 1), true)
                        ) : (
                            <NoData imageWidth={100} img={good} text={t("noAssignmentsInProgress")} />
                        )}
                    </Col>
                    <Col span={24}>
                        <Title level={4} className="flex__between__center">
                            {t("upcomingAssignments")}{" "}
                            <Button type="link" onClick={() => navigate(`/exercises`)}>
                                XEM TẤT CẢ
                            </Button>
                        </Title>

                        {assignmentData?.NOT_YET_STARTED?.length ? (
                            renderAssignments(assignmentData.NOT_YET_STARTED.slice(0, 3), true)
                        ) : (
                            <NoData imageWidth={100} img={good} text={t("noAssignmentsInWaiting")} />
                        )}
                    </Col>
                </Row>
            </Col>
            <Col xs={24} md={24} lg={10} xl={8}>
                <div>
                    <Title level={4} className="flex__between__center">
                        {t("dailyReport")}{" "}
                        <Button size="small" onClick={() => navigate("/report")} type="link">
                            {t("view")}
                        </Button>
                    </Title>
                    {reportData ? (
                        <div className="card --padding --lg">
                            <div className="flex__start__center mb" style={{ marginBottom: 12 }}>
                                <img src={avatar} alt={reportData.teacher.name} style={{ width: 50, height: 50, borderRadius: "50%", marginRight: 10 }} />
                                <div>
                                    <Typography.Title level={5}>{reportData.teacher.name}</Typography.Title>
                                    <Typography.Text type="secondary">{moment(reportData.created_at).format("DD/MM/YYYY")}</Typography.Text>
                                </div>
                            </div>
                            <Typography.Text>
                                {reportData?.class_homework?.content?.split("\n")?.map((item, index) => (
                                    <span key={index}>
                                        {item}
                                        {index < reportData.class_homework.content?.split("\n").length - 1 && <br />}
                                    </span>
                                ))}
                            </Typography.Text>
                        </div>
                    ) : (
                        <div className="card --padding --lg card__report flex__start__center gap">
                            <img src={bee_sad} alt="bee_sad" />
                            <Typography.Text>{t("noDailyReportAvailable")}</Typography.Text>
                        </div>
                    )}
                </div>
                <br />
                <div>
                    <Title level={4}>Hoạt động</Title>
                    <div className="card card_assignment __TEST">
                        <Typography.Title level={5}>Làm bài 0 tiếng 0 phút</Typography.Title>
                        <Typography.Text type="danger">+0% sv. tuần trước</Typography.Text>
                    </div>
                    <div className="card card_assignment __NOGRADE">
                        <Typography.Title level={5}>Đọc sách 0 tiếng 0 phút</Typography.Title>
                        <Typography.Text type="success">+0% sv. tuần trước</Typography.Text>
                    </div>
                </div>
                <br />
                <div>
                    <Title level={4}>Kĩ năng</Title>
                    <StudentChartSkill />
                </div>
            </Col>
        </Row>
    );
}

export default StudentDashboard;

import React, { useContext, useState } from "react";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    notification,
    Radio,
    Row,
    Switch,
    TimePicker,
    Tooltip,
    Typography,
} from "antd";
import { FieldTimeOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import CustomBookIconLarge from "../../components/Icons/CustomBookIconLarge";
import ModalSelectBook from "./components/ModalSelectBook";
import { copyText, debounce, disabledDate, imageFallback } from "../../utils";
import CustomTrashIcon from "../../components/Icons/CustomTrashIcon";
import CustomEditIcon from "../../components/Icons/CustomEditIcon";
import CustomClassIcon from "../../components/Icons/CustomClassIcon";
import { StoreContext } from "../../stores";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { handleFetchApi } from "../../configs/apiRequest";
import CustomSuccessIcon from "../../components/Icons/CustomSuccessIcon";
import CustomCopyIcon from "../../components/Icons/CustomCopyIcon";
import CustomWarningIcon from "../../components/Icons/CustomWarningIcon";
import SelectClass from "./components/SelectClass";
import { AASSIGNMENT } from "../../apis/exercise";

const format = "HH:mm";

const initAssignmentDetail = {
    name: null,
    type: null,
    deadline_at: null,
    description: null,
    time_down: null,
    class_id: null,
    teacher_id: null,
    item_ids: [],
    date: null,
    time: null,
};

function GiveAssignment(props) {
    const { t } = useTranslation();
    const { selectedClass, setLoading, assignmentExercises, me } = useContext(StoreContext);
    const { isOpen, type, onClose, book } = props;
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [assignmentDetail, setAssignmentDetail] = useState({ ...initAssignmentDetail, type: type });
    const [selectedBook, setSelectedBook] = useState(book);
    const [selectedItems, setSelectedItems] = useState(assignmentExercises);
    const [selectClass, setSelectClass] = useState({
        class_id: selectedClass?.id,
        course_id: selectedClass?.courseId,
    });

    const giveAssignment = useMutation({
        mutationFn: () => {
            setLoading(true);
            message.loading(t("creating_test"));
            const items = selectedItems?.map((i) => i.id);
            const assignment = {
                ...assignmentDetail,
                item_ids: items,
                deadline_at: `${assignmentDetail?.date} ${assignmentDetail?.time}:00`,
                teacher_id: me?.id,
                class_id: selectClass?.class_id,
                course_id: selectClass?.course_id,
                type: type,
            };
            delete assignment.date;
            delete assignment.time;
            if (!assignmentDetail?.date || !assignmentDetail.time) {
                delete assignment.deadline_at;
            }

            return handleFetchApi(AASSIGNMENT.create({ data: assignment }));
        },
        onSuccess: async (response, variables) => {
            message.destroy();
            message.success(t("msg_success"));
            const url = `${window.location.origin}/assignment/${response?.data?.id}`;

            setLoading(false);
            return Modal.confirm({
                title: <Typography.Text strong>{t("test_created_successfully")}</Typography.Text>,
                icon: <CustomSuccessIcon />,
                closable: true,
                width: 600,
                centered: true,
                content: (
                    <>
                        <Typography.Text type="secondary">{t("test_link")}</Typography.Text>
                        <Input.Group compact size="large" className="flex__center__center">
                            <Input defaultValue={url} disabled />
                            <Tooltip title={t("click_to_copy_url")}>
                                <Button
                                    onClick={debounce(() => {
                                        copyText(url);
                                    })}
                                    size="large"
                                >
                                    {t("copy")} <CustomCopyIcon />
                                </Button>
                            </Tooltip>
                        </Input.Group>
                    </>
                ),
                okText: t("go_to_homepage"),
                cancelText: t("assign_another"),
                okButtonProps: { className: "--md" },
                cancelButtonProps: { className: "--md" },
                onOk: () => {
                    Modal.destroyAll();
                    onClose();
                },
                onCancel: () => {
                    Modal.destroyAll();
                },
            });
        },
    });

    return (
        <Drawer
            footer={
                <div className="flex__end__center">
                    <Button
                        onClick={() => {
                            if (!assignmentDetail.name) {
                                return notification.error({ message: t("test_name_missing") });
                            }
                            if (
                                type === "TEST" &&
                                (!assignmentDetail?.date || !assignmentDetail?.time || !assignmentDetail?.time_down || assignmentDetail?.time_down <= 0)
                            ) {
                                return notification.error({ message: t("test_deadline_or_duration_missing") });
                            }

                            if (!selectedItems?.length) {
                                return notification.error({ message: t("missing_content") });
                            }
                            if (!selectClass) return notification.error({ message: t("select_class_for_assignment") });

                            return Modal.confirm({
                                title: <Typography.Title level={3}>{t("confirm_assignment")}</Typography.Title>,
                                closable: true,
                                icon: null,
                                width: 500,
                                onOk: giveAssignment.mutate,
                                okText: t("assign"),
                                cancelText: t("cancel"),
                                content: (
                                    <>
                                        <Typography.Text type="secondary">{t("check_assignment_details")}</Typography.Text>
                                        <br />
                                        <div className="card --padding">
                                            <div className="flex__start__start flex__column gap-sm">
                                                <Typography.Text>
                                                    {t("assignment_title")}: <b>{assignmentDetail?.name || t("not_set")}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("deadline")}:{" "}
                                                    <b>{`${assignmentDetail?.date ?? t("no_date")} ${assignmentDetail?.time ?? t("no_time")}`}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("details")}: <b>{assignmentDetail?.description || t("not_set")}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("duration_minutes")}: <b>{assignmentDetail?.time_down || t("not_set")}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("assigned_to")}:{" "}
                                                    <b>
                                                        {selectedClass?.name
                                                            ? `${selectedClass?.grade}${selectedClass?.name} - ${selectedClass?.school?.name}`
                                                            : t("not_selected")}
                                                    </b>
                                                </Typography.Text>
                                                <hr />
                                                <Typography.Text>
                                                    {t("number_of_items")}:{" "}
                                                    <b>
                                                        {selectedItems?.length ?? 0} {t("items")}
                                                    </b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("item_types")}: <b>{t("listening_writing_speaking")}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("exercise_types")}: <b>{t("fill_in_blanks_matching_drag_drop_writing")}</b>
                                                </Typography.Text>
                                                <Typography.Text>
                                                    {t("media_types")}: <b>{t("audio")}</b>
                                                </Typography.Text>
                                            </div>
                                        </div>
                                    </>
                                ),
                            });
                        }}
                        type="primary"
                        size="large"
                    >
                        {t("assign_btn")}
                    </Button>
                </div>
            }
            className="ant-drawer--large give_assignment"
            width={"100dvw"}
            title={`${t("assign")} ${type === "TEST" ? t("test") : t("homework")}`}
            placement="right"
            onClose={onClose}
            open={isOpen ?? false}
            size="large"
        >
            <Row className="give_assignment__content" gutter={[16, 16]}>
                <Col sm={24} md={24} lg={14} xl={16}>
                    <div className="give_assignment__content__allinfo flex__start__start gap flex__column">
                        <Typography.Title level={4}>{t("general_information")}</Typography.Title>
                        <div className="baseinfo">
                            <div>
                                <Typography.Text strong>{t("name")}</Typography.Text>
                                <Input
                                    onChange={debounce((e) => setAssignmentDetail((prev) => ({ ...prev, name: e?.target.value })), 300)}
                                    size="large"
                                    placeholder={t("name")}
                                />
                            </div>
                            <div>
                                <Typography.Text strong>{t("submission_time")}</Typography.Text>
                                <br />
                                <TimePicker
                                    onChange={(e, timeString) => {
                                        setAssignmentDetail((prev) => ({ ...prev, time: timeString }));
                                    }}
                                    popupClassName="fixed-picker"
                                    size="large"
                                    format={format}
                                />
                            </div>
                            <div>
                                <Typography.Text strong>{t("submission_deadline")}</Typography.Text>
                                <br />
                                <DatePicker
                                    onChange={(e, dateString) => {
                                        setAssignmentDetail((prev) => ({ ...prev, date: dateString }));
                                    }}
                                    format={"YYYY-MM-DD"}
                                    size="large"
                                    disabledDate={disabledDate}
                                />
                            </div>
                        </div>
                        <div>
                            <Typography.Text strong>{t("details")}</Typography.Text>
                            <Input.TextArea
                                onChange={debounce((e) => setAssignmentDetail((prev) => ({ ...prev, description: e?.target.value })), 300)}
                                showCount={true}
                                maxLength={300}
                                rows={5}
                                placeholder={t("enter_information")}
                            ></Input.TextArea>
                        </div>
                        <div>
                            <Typography.Title level={5} strong>
                                {t("duration_minutes")}{" "}
                                <Switch
                                    onChange={(checked) => {
                                        setAssignmentDetail((prev) => ({ ...prev, time_down: checked ? 30 : null }));
                                    }}
                                />
                            </Typography.Title>
                            {assignmentDetail?.time_down && (
                                <div className="flex__start__center gap select_time">
                                    <Radio.Group
                                        defaultValue={30}
                                        onChange={(e) => setAssignmentDetail((prev) => ({ ...prev, time_down: e?.target?.value }))}
                                        size="large"
                                    >
                                        <Radio value={30}>30</Radio>
                                        <Radio value={45}>45</Radio>
                                        <Radio value={60}>60</Radio>
                                        <Radio value={90}>90</Radio>
                                        <Radio value={-1}>{t("other")}</Radio>
                                    </Radio.Group>
                                    <InputNumber
                                        prefix={<FieldTimeOutlined />}
                                        className={`other_time ${![30, 45, 60, 90].includes(assignmentDetail?.time_down) && "--open"}`}
                                        size="large"
                                        max={300}
                                        min={5}
                                        placeholder={t("enter_other_number")}
                                        onChange={debounce((e) => setAssignmentDetail((prev) => ({ ...prev, time_down: Number(e?.target?.value) || -1 })))}
                                    />
                                </div>
                            )}
                        </div>
                        <SelectClass setSelectClass={setSelectClass} selectClass={selectClass} />
                    </div>
                </Col>
                <Col sm={24} md={24} lg={10} xl={8}>
                    <div className="give_assignment__content__topic">
                        <Typography.Title level={4}>{t("selectAssignment")}</Typography.Title>
                        {selectedBook ? (
                            <div className="flex__start__start flex__column">
                                <div className="flex__start__start gap">
                                    <Image
                                        width={"50%"}
                                        preview={false}
                                        src={selectedBook?.cover_url || selectedBook?.book_cover_url}
                                        fallback={imageFallback}
                                        alt="book_cover"
                                    />
                                    <div className="flex__start__start flex__column gap-sm">
                                        <Typography.Title level={5}>{selectedBook?.title}</Typography.Title>
                                        <Typography.Text>
                                            {t("author")}: <b>{selectedBook?.author ?? t("no_information")}</b>
                                        </Typography.Text>
                                        <Typography.Text>
                                            {t("publisher")}: <b>{selectedBook?.publisher ?? t("no_information")}</b>
                                        </Typography.Text>
                                        <Typography.Text>
                                            {t("description")}: <b>{selectedBook?.description ?? t("no_information")}</b>
                                        </Typography.Text>
                                    </div>
                                </div>
                                <Divider />
                                <div className="flex__start__start flex__column gap-sm">
                                    <Typography.Text>
                                        {t("number_of_items")}:{" "}
                                        {selectedItems?.length ? (
                                            <b>
                                                {selectedItems?.length} {t("items")}
                                            </b>
                                        ) : (
                                            <b>{t("not_selected")}</b>
                                        )}
                                    </Typography.Text>
                                    <Typography.Text>
                                        {t("item_types")}: <b>{t("listening_writing_speaking")}</b>
                                    </Typography.Text>
                                    <Typography.Text>
                                        {t("exercise_types")}: <b>{t("fill_in_blanks_matching_drag_drop_writing")}</b>
                                    </Typography.Text>
                                    <Typography.Text>
                                        {t("media_types")}: <b>{t("audio")}</b>
                                    </Typography.Text>
                                </div>
                                <Divider />
                                <div className="flex__center__center gap">
                                    <Button
                                        onClick={() =>
                                            Modal.confirm({
                                                title: t("confirm_delete_selected_assignment"),
                                                cancelText: t("cancel"),
                                                okType: "danger",
                                                okText: t("delete"),
                                                icon: <CustomWarningIcon />,
                                                closable: true,
                                                centered: true,
                                                onOk: () => {
                                                    setSelectedItems(undefined);
                                                    setSelectedBook(undefined);
                                                },
                                            })
                                        }
                                        style={{ width: "49%" }}
                                        icon={<CustomTrashIcon />}
                                        type="text"
                                        className="btn-white --md"
                                        danger
                                    >
                                        {t("delete_this_assignment")}
                                    </Button>
                                    <Button onClick={() => setIsOpenModal(true)} style={{ width: "49%" }} icon={<CustomEditIcon />} className="btn-white --md">
                                        {t("edit_assignment")}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="card --hoverable flex__center__center flex__column gap" onClick={() => setIsOpenModal(true)}>
                                    <CustomBookIconLarge />
                                    <Typography.Text>
                                        {t("select_from_library")} <RightOutlined />
                                    </Typography.Text>
                                </div>
                                <br />
                                <div className="card flex__center__center flex__column gap">
                                    <Typography.Text>{t("coming_soon")}</Typography.Text>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            <ModalSelectBook
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
            />
        </Drawer>
    );
}

export default GiveAssignment;

import React, { useContext, useEffect } from "react";
import { Modal, Form, Input, Select, Button, notification, Row, Col } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../../stores";
import MentionsStudent from "./MentionsStudent";
import { AREPORT } from "../../../apis/report";

const { TextArea } = Input;
const { Option } = Select;

const CreateDailyReport = ({ open, close, initData, type }) => {
    const { selectedClass, me } = useContext(StoreContext);
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const createReportMutation = useMutation({
        mutationFn: async (reportData) => {
            const response = await handleFetchApi(
                AREPORT.createDaily({
                    data: {
                        ...reportData,
                        class_id: selectedClass?.id,
                        course_id: selectedClass?.courseId,
                        teacher_id: me?.id,
                        type: "DAILY",
                        is_confirmed: false,
                    },
                })
            );

            if (response?.status === "S0000") {
                return response;
            } else {
                notification.error({
                    message: t("error"),
                    description: "Không thể tạo báo cáo vào lúc này.",
                });
            }
        },
        onSuccess: async (response, error) => {
            console.log("response:", response);
            if (response?.status === "S0000") {
                notification.success({
                    message: t("success"),
                    description: t("daily_report_created_successfully"),
                });
                queryClient.invalidateQueries("report");
                close({ initData: response?.data });
                form.resetFields();
            }
        },
        onError: (err) => {
            notification.error({
                message: t("error"),
                description: t("failed_to_create_daily_report") + err.message,
            });
        },
    });

    const updateReportMutation = useMutation({
        mutationFn: async (reportData) => {
            const response = await handleFetchApi(AREPORT.update({reportId: initData.id, data: reportData}));
            if (response?.status === "S0000") {
                return response;
            } else {
                notification.error({
                    message: t("error"),
                    description: "Bạn không có quyền chỉnh sửa.",
                });
            }
        },
        onSuccess: async (response, error) => {
            if (response?.status === "S0000") {
                notification.success({
                    message: t("success"),
                    description: t("daily_report_created_successfully"),
                });
                queryClient.invalidateQueries("report");

                close({ initData: { ...response?.data, id: initData.id } });
                form.resetFields();
            }
        },
        onError: (err) => {
            notification.error({
                message: t("error"),
                description: t("failed_to_create_daily_report") + err.message,
            });
        },
    });

    // Update form values when initData changes
    useEffect(() => {
        if (initData) {
            form.setFieldsValue(initData);
        }
    }, [initData, form]);

    return (
        <Modal zIndex={1001} centered={true} open={open} title={t("create_daily_report")} onCancel={close} footer={null} width={"90dvw"} ariaHidden="false">
            <Form
                form={form}
                layout="vertical"
                onFinish={(values) => (type === "CREATE" ? createReportMutation.mutate(values) : updateReportMutation.mutate(values))}
                requiredMark={false}
            >
                <Row gutter={[24, 12]}>
                    <Col span={24}>
                        <Form.Item name="content" label={t("content")} rules={[{ required: true, message: t("please_input_content") }]}>
                            <TextArea rows={8} placeholder={t("learning_content")} size="large" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={["class_homework", "content"]}
                            label={t("homework_content")}
                            rules={[{ required: true, message: t("please_input_homework_content") }]}
                        >
                            <TextArea placeholder={t("homework_content_placeholder")} rows={7} size="large" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            name={["class_report_status", "discipline"]}
                            label={t("discipline_status")}
                            rules={[{ required: true, message: t("please_select_discipline_status") }]}
                        >
                            <Select size="large" placeholder={t("select_level")}>
                                <Option value="Good">{t("Good")}</Option>
                                <Option value="Average">{t("Average")}</Option>
                                <Option value="NeedsImprovement">{t("NeedsImprovement")}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={["class_report_status", "discipline_content"]} label={t("content")}>
                            <TextArea rows={3} size="large" maxLength={200} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            name={["class_report_status", "study"]}
                            label={t("study_status")}
                            rules={[{ required: true, message: t("please_select_study_status") }]}
                        >
                            <Select size="large" placeholder={t("select_level")}>
                                <Option value="Excellent">{t("Excellent")}</Option>
                                <Option value="Good">{t("Good")}</Option>
                                <Option value="Average">{t("Average")}</Option>
                                <Option value="NeedsImprovement">{t("NeedsImprovement")}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={["class_report_status", "study_content"]} label={t("content")}>
                            <TextArea rows={3} size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Form.Item name="commend" label={t("commendation")} rules={[{ required: true, message: t("please_input_commendation") }]}>
                            <MentionsStudent type="GOOD" classId={selectedClass?.id} initValue={initData?.commend} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="criticize" label={t("reminder")} rules={[{ required: true, message: t("please_input_reminder") }]}>
                            <MentionsStudent type="BAD" classId={selectedClass?.id} initValue={initData?.criticize} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div className="flex__end__center gap">
                        <Button size="large" className="btn-white --border-dark" onClick={close}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" htmlType="submit" loading={createReportMutation.isLoading} size="large">
                            {type === "CREATE" ? t("create_report") : t("confirm")}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateDailyReport;

import { jumpToExercise } from ".";
import { isEmpty, isEmptyFill } from "../../../utils";

export const getDataToSubmit = ({ selectedExercise, user_id, choice, record, fill, matched, writing, dragList, exerciseChildren, exerciseResult }) => {
    const itemIds = selectedExercise?.assignment?.itemIds || [selectedExercise?.children[0]?.id];
    const { type } = selectedExercise;
    return (
        itemIds?.reduce((itemsResultAssignment, itemId) => {
            const item = exerciseChildren?.get(itemId);
            const type = item?.content?.exercise_type;
            const isTextBased = item?.content?.is_text_based;
            let content = {};

            switch (type) {
                case "choice":
                case "choice_many":
                    const choiceToSave = Object.entries(choice)
                        .filter(([key]) => key.split("_")[1] === item.id)
                        .flatMap(([, value]) => value?.map((e) => e.id));

                    if (isTextBased) {
                        choiceToSave.push(choice[item.id]);
                        content = { text_choice: choiceToSave[0] };
                    } else {
                        content = { choice: choiceToSave };
                    }
                    break;

                case "tick_cross":
                    const tickToSave = item?.content?.children?.reduce((acc, childId) => {
                        acc[childId] = fill[childId] ?? "";
                        return acc;
                    }, {});
                    content = { tick_cross: fill[item.id] || tickToSave };
                    break;

                case "fill":
                    if (isTextBased) {
                        content = { text_fill: [fill[item?.id]?.value] };
                    } else {
                        const fillToSave = item?.content?.children?.reduce((acc, childId) => {
                            acc[childId] = fill[childId] ?? "";
                            return acc;
                        }, {});
                        content = { fill: fill[item.id] || fillToSave };
                    }
                    break;

                case "matching":
                    if (isTextBased) {
                        const textMatching = Object.entries(matched)
                            .filter(([key]) => key.split("_")[1] === item.id)
                            .flatMap(([, value]) =>
                                value.connectText?.map((element) => ({
                                    matchingValue1: value.value,
                                    matchingValue2: element,
                                }))
                            );
                        content = { text_matching: textMatching };
                    } else {
                        content = { matching: matched[item.id] ?? {} };
                    }
                    break;

                case "writing":
                    const fillToSave = item?.content?.children?.reduce((acc, childId) => {
                        acc[childId] = writing[childId] ?? "";
                        return acc;
                    }, {});
                    console.log("writing:", fillToSave);

                    content = { writing: writing[item.id] || fillToSave };
                    break;
                case "voice":
                    content = { voice: record };
                    break;

                case "drag_drop":
                    const dragDropToSave = Object.entries(dragList)
                        .filter(([key]) => key.split("_")[1] === item.id)
                        .reduce((acc, [key, value]) => {
                            acc[key.split("_")[0]] = value;
                            return acc;
                        }, {});
                    content = { drag_drop: dragDropToSave ?? {} };
                    break;

                default:
                    return itemsResultAssignment;
            }

            itemsResultAssignment.push({
                item_id: item.id,
                user_id,
                content,
            });

            return itemsResultAssignment;
        }, []) || []
    );
};

export const validateDataToSubmit = ({ dataToSubmit, exerciseChildren }) => {
    for (const element of dataToSubmit || []) {
        const { item_id, content } = element;
        const itemMapped = exerciseChildren.get(item_id);
        const exerciseType = itemMapped?.content?.exercise_type;
        if ((exerciseType === "choice" || exerciseType === "choice_many") && (!content?.choice || !content.choice.length)) {
            jumpToExercise(item_id);
            return false;
        }

        if ((exerciseType === "fill" || exerciseType === "tick_cross") && isEmptyFill(content?.fill)) {
            jumpToExercise(item_id);
            return false;
        }

        if (exerciseType === "matching" && isEmpty(content?.matching)) {
            jumpToExercise(item_id);
            return false;
        }
    }

    return true;
};
